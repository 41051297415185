exports.urls = {
	public: {
		retailers: "/public/retailers",
		products: "/public/products",
		registerProduct: "/registration/register",
		ouath2: "/oauth2/token",
		s3bucketurl: "/data-product-registration-nonprod",
		externalCall: "/public/externalCall",
		registriaCall: "/public/registriaCall",
		campaignId: "/public/getCampaignDetails"
	},
	private: {
		registered_products: "/user/registered-products",
		delete_product: "/user/delete-product",
		upload_proof: "/user/upload-proof",
		delete_proof: "/user/delete-proof",
		protection_plan: "/user/getProtectionPlan",
		registerProduct: "/user/register",
		s3PreSignedUrl: "/user/signedUrl",
		getS3Object: "/user/getS3SignedUrlToDownload"
	}
};
exports.prod_reCAPTCHA_SITE_KEY = '6LetaospAAAAACXPp4JXMQDeAsHJix5Z8jvFxyil'
exports.API = 'https://gwxfqk6l63.execute-api.us-east-1.amazonaws.com/api';
// exports.API = 'http://localhost:5000';
exports.S3_API = 'https://s3.amazonaws.com';
exports.app_client_id = 'dcc0b327-fea4-4382-a790-d3c7c4fe12db';
exports.MDCIM_OAUTH_URL = 'https://acm.account.sony.com'
exports.signout_url = `${exports.MDCIM_OAUTH_URL}/delete_session?`
// Not Used 
exports.redirect_uri = '';
// Not Used 
exports.signout_uri = '';
// Not Used
exports.state = '12TedVyHwWNIBpGbWa_6ViaOD3tZUtlNajTXuBZQBac';
// Not Used
exports.nonce = 'PtMwIy8CxS5c1Mp7ubdBK9gM1Q6tFcTynUfLazbNPhs';

exports.accountSettingUrl = `${exports.MDCIM_OAUTH_URL}/account/menu?`;
exports.footer_url = 'https://www.sony.com/app/shared/footer?includeJs=true&includeCss=true&includeFontType=true&enable_sony_sites=true'
exports.registria_redirect_url = 'https://protect.electronics.sony.com/product_care'
exports.zvUniverse = "https://alphauniverse.com/zvcampaign"